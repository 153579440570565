import { useEffect } from "react";
import { setDefaultLocale } from "react-datepicker";
import { I18nextProvider } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/navigation/NavBar/NavBar";
import PublicNavBar from "./components/navigation/PublicNavBar/PublicNavBar";
import StudentNavBar from "./components/navigation/StudentNavBar/StudentNavBar";
import ProtectedRoute from "./components/routing/ProtectedRoute/ProtectedRoute";
import i18n from "./lang/i18n";

import Login from "./pages/Auth/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import StudentProfile from "./pages/StudentPage/StudentProfile.js";
import QuestionsAdmin from "./pages/QuestionsAdmin/Questions";
import SnackbarProvider from "./utils/contexts/SnackbarProvider";
import { UserContext } from "./utils/contexts/UserContext";
import useFindUser from "./utils/hooks/useFindUser";
import SubscriptionPlansStudent from "./pages/SubscriptionPlans/SubscriptionPlansStudent/SubscriptionPlansStudent.js";
import StudentOrder from "./pages/Order/StudentOrder/StudentOrder.js";
import ExaminationsAdmin from "./pages/Examinations/Exams/Admin/ExaminationsAdmin.js";
import ExaminationStudentResult from "./pages/Examinations/Result/Student/ExaminationStudentResult.js";
import ExaminationStudentForm from "./pages/Examinations/Exam/Student/Examination.js";
import ExaminationsStudent from "./pages/Examinations/Exams/Student/ExaminationsStudent.js";
import Registration from "./pages/Auth/Registration/Registration.js";
import Confirm from "./pages/Auth/Confirm/Confirm.js";
import ResultsStatistics from "./pages/Stats/TestStats/ResultsStats.js";
import OrderStatistics from "./pages/Stats/PaymentStats/OrderStats.js";
import PasswordReset from "./pages/Auth/PasswordReset/PasswordReset.js";
import PasswordResetConfirm from "./pages/Auth/PasswordReset/PasswordResetConfirm.js";
import StudentSettings from "./pages/Settings/StudentSettings/StudentSettings.js";
import AccountStatistics from "./pages/Stats/AccountsStats/AccountsStats.js";
import Landing from "./pages/Landing/Landing.js";

function App() {
  const { user, setUser, isLoading } = useFindUser();

  useEffect(() => {
    setDefaultLocale(localStorage.getItem("i18nextLng"));

    return () => {};
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <SnackbarProvider>
          <Routes>
            {/* Public part */}
            <Route index element={<Landing />} />

            {/* <Route index element={<Login />} /> */}

            <Route element={<PublicNavBar />}>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/confirm/:token/" element={<Confirm />} />
              <Route path="/password-reset/" element={<PasswordReset />} />
              <Route
                path="/password-reset/:token"
                element={<PasswordResetConfirm />}
              />
            </Route>

            {/* Private part */}
            {/* Admin side */}
            <Route
              element={
                <ProtectedRoute
                  // redirectPath="/applicants"
                  isAllowed={
                    !!user?.uuid &&
                    user?.roles?.some((role) => role?.code === "ADMIN")
                  }
                  isLoading={isLoading}
                />
              }
            >
              <Route
                path="/"
                element={<NavBar user={user} setUser={setUser} />}
              >
                <Route
                  path="admin/stats/results"
                  element={<ResultsStatistics />}
                />
                <Route
                  path="admin/stats/orders"
                  element={<OrderStatistics />}
                />
                <Route
                  path="admin/stats/accounts"
                  element={<AccountStatistics />}
                />
                <Route
                  path="admin/exams/:examUUID/questions"
                  element={<QuestionsAdmin />}
                />
                <Route path="admin/exams" element={<ExaminationsAdmin />} />
              </Route>
            </Route>

            {/* Student side */}
            <Route
              element={
                <ProtectedRoute
                  isAllowed={
                    !!user?.uuid &&
                    user?.roles?.some((role) => role?.code === "STD")
                  }
                  isLoading={isLoading}
                />
              }
            >
              <Route
                path="/student"
                element={<StudentNavBar user={user} setUser={setUser} />}
              >
                <Route path="profile" element={<StudentProfile />} />
                <Route
                  path="subscriptions"
                  element={<SubscriptionPlansStudent />}
                />
                <Route
                  path="exam/:examUUID/result"
                  element={<ExaminationStudentResult />}
                />
                <Route
                  path="exam/:examUUID"
                  element={<ExaminationStudentForm />}
                />
                <Route path="order/:uuid" element={<StudentOrder />} />
                <Route path="exams" element={<ExaminationsStudent />} />
                <Route path="settings" element={<StudentSettings />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SnackbarProvider>
      </UserContext.Provider>
    </I18nextProvider>
  );
}

export default App;
