import React, { useRef } from "react";
import { useState } from "react";
import { Button } from "../../../../../components/forms/Buttons/Button";
import CustomDialog from "../../../../../components/modals/CustomDialog/CustomDialog";
import useSnackbar from "../../../../../utils/hooks/useSnackbar";
import { CameraIcon, EyeIcon } from "@heroicons/react/24/outline";
import { patchExamText } from "../../../../../services/tests/examsService";

const EditText = ({ text, header, getData }) => {
  const { addSnackbar } = useSnackbar();

  const [image, setImage] = useState(text?.image);
  const [imageFile, setImageFile] = useState();
  const [open, setOpen] = useState(false);
  const fileRef = useRef();

  const updateTextHandler = () => {
    const Body = new FormData();
    if (!image) {
      addSnackbar("Загрузите изображение", "error");
      return;
    }
    Body.append("image", imageFile);
    setOpen(false);

    patchExamText(text?.uuid, Body)?.finally(() => {
      addSnackbar("Текст успешно обновлен", "success");
      getData();
    });
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files[0].type.substring(0, 5) !== "image") {
      addSnackbar(
        "Жүктелген файл сурет болуы керек! / Загружаемый вами файл должен быть изображением!",
        "error"
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      addSnackbar(
        "Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ",
        "error"
      );
      return;
    } else {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);

      setImageFile(file);
    }
  };

  return (
    <>
      <Button
        text="Посмотреть текст"
        Icon={EyeIcon}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      />
      <CustomDialog
        isOpen={open}
        headerText={header}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <div className="flex flex-col ">
              <center className="my-2">
                {image ? (
                  <img className="img-responsive" alt="question" src={image} />
                ) : (
                  <div className="flex h-52 w-full flex-col justify-center rounded-lg bg-yellow-300 text-center">
                    Нет загруженного изображения
                  </div>
                )}
              </center>
              <div className="flex flex-row justify-end">
                <Button
                  className="max-w-fit"
                  Icon={CameraIcon}
                  text="Загрузить"
                  color="primary"
                  onClick={() => fileRef.current.click()}
                />
              </div>
              <input
                hidden
                type="file"
                ref={fileRef}
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              text="Отмена"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            />
            <Button
              text="Обновить"
              color="success"
              onClick={() => updateTextHandler()}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default EditText;
