import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { FunnelIcon } from "@heroicons/react/24/solid";
import React from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { PageButton } from "../forms/Buttons/PageButton";
import { Button } from "../forms/Buttons/Button";
import SimpleLoader from "../Loader/SimpleLoader";

export default function Table({
  columns,
  data,
  count,
  pageNumber,
  nextPage,
  previousPage,
  gotoPage,
  pageCount,
  loading,
  isSticky,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: 50 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  if (loading) {
    return (
      <div className="text-center">
        <SimpleLoader className="mt-12 h-12" />
      </div>
    );
  }

  return (
    <>
      {headerGroups?.map((headerGroup) =>
        headerGroup.headers?.map((column) =>
          column.Filter ? (
            <div key={column.id}>{column.render("Filter")}</div>
          ) : null
        )
      )}
      <div className="flex items-center justify-between py-3">
        <div className="flex flex-1 justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!pageNumber === 1}>
            Previous
          </Button>
          <Button
            onClick={() => nextPage()}
            disabled={!pageNumber === pageCount}
          >
            Next
          </Button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div className="flex gap-x-2">
            {count && (
              <div>
                Всего: <span className="font-bold">{count}</span>
              </div>
            )}
            {pageNumber && (
              <span className=" text-gray-700">
                | Страница <span className="font-medium">{pageNumber}</span> из
                <span className="font-medium">{" " + pageCount}</span>
              </span>
            )}

            {/* <label>
                <span className='sr-only'>Items Per Page</span>
                <select
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                  value={state.pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20]?.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </label> */}
          </div>
          {pageCount > 1 && (
            <div>
              <nav
                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <PageButton
                  className="rounded-l-md"
                  onClick={() => gotoPage(1)}
                  disabled={pageNumber === 1}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </PageButton>
                <PageButton
                  onClick={() => previousPage()}
                  disabled={pageNumber === 1}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                  onClick={() => nextPage()}
                  disabled={pageNumber === pageCount}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                  className="rounded-r-md"
                  onClick={() => gotoPage(pageCount)}
                  disabled={pageNumber === pageCount}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </PageButton>
              </nav>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div
              className={`${
                isSticky ? "max-h-[40rem] overflow-y-scroll" : "overflow-hidden"
              } border-b border-gray-200 shadow sm:rounded-lg`}
            >
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead
                  className={`${isSticky && "sticky top-0 z-10"} bg-gray-50`}
                >
                  {headerGroups?.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers?.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group max-w-th p-1 text-left text-sm text-gray-600"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between break-all">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                                ) : (
                                  <ChevronUpIcon className="h-4 w-4 text-gray-400" />
                                )
                              ) : (
                                <FunnelIcon className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-white"
                >
                  {loading ? (
                    <tr>
                      {/* {headerGroups.map(() => ( */}
                      <td className="px-2 py-1">loading...</td>
                      {/* ))} */}
                    </tr>
                  ) : (
                    page?.map((row, i) => {
                      // new
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells?.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="border px-2 py-1"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
