import bilAPI from "../api";

export const postPasswordReset = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/auth/password-reset/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postPasswordResetForm = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/auth/password-reset-form/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postPasswordResetConfirm = ({ token, password }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/password-reset/confirm/${token + token && "/"}`, {
          password,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
