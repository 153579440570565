import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../components/forms/Input/Input";
import { useForm } from "react-hook-form";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Button } from "../../../components/forms/Buttons/Button";
import { UserContext } from "../../../utils/contexts/UserContext";
import { postPasswordReset } from "../../../services/auth/passwordService";
import { PASSWORD_RESET_ERROR_CODE } from "../../../utils/constants/constants";
import useSnackbar from "../../../utils/hooks/useSnackbar";

const PasswordForm = () => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
  } = useForm();

  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isVisibleCurrent, setIsVisibleCurrent] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    postPasswordReset({
      password: data.password,
      current_password: data.current_password,
    })
      .then(() => {
        addSnackbar(
          t("signup.updated_successfully", { field: t("welcome.password") })
        );
      })
      .catch((err) => {
        if (err?.response?.data?.code in PASSWORD_RESET_ERROR_CODE) {
          addSnackbar(t(PASSWORD_RESET_ERROR_CODE[err?.response?.data?.code]));
        }
      })
      ?.finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-row relative w-80">
        <Input
          className="w-80"
          label={t("welcome.current_password")}
          placeholder={t("signup.enter_password")}
          type={isVisibleCurrent ? "text" : "password"}
          required
          {...register("current_password", {
            required: t("signup.is_required_field_error", {
              field: t("welcome.current_password")?.toLowerCase(),
            }),
            maxLength: 64,
          })}
        />
        <div className="absolute right-2 top-7 cursor-pointer text-primary">
          {isVisibleCurrent ? (
            <EyeIcon
              className="h-5"
              onClick={() => setIsVisibleCurrent(false)}
            />
          ) : (
            <EyeSlashIcon
              className="h-5"
              onClick={() => setIsVisibleCurrent(true)}
            />
          )}
        </div>
      </div>
      <a
        href="/password-reset"
        className="text-center text-primary hover:underline"
      >
        {t("signup.forgot_password")}
      </a>

      <div className="flex flex-row relative w-80">
        <Input
          errorMsg={errors?.password?.message}
          inputClass={errors?.password?.message && "ring-error ring-1"}
          className="w-80"
          label={t("welcome.password")}
          placeholder={t("signup.enter_password")}
          type={isVisible ? "text" : "password"}
          required
          {...register("password", {
            required: t("signup.is_required_field_error", {
              field: t("welcome.password")?.toLowerCase(),
            }),
            maxLength: 64,
            minLength: {
              value: 8,
              message: [1, 2, 3, 4, 5]
                ?.map((e) => t(`signup.password_error.err_${e}`))
                ?.join("\n"),
            },
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
              message: [1, 2, 3, 4, 5]
                ?.map((e) => t(`signup.password_error.err_${e}`))
                ?.join("\n"),
            },
            validate: (value) => {
              if (value === user.email)
                return [1, 2, 3, 4, 5]
                  ?.map((e) => t(`signup.password_error.err_${e}`))
                  ?.join("\n");
            },
          })}
        />
        <div className="absolute right-2 top-7 cursor-pointer text-primary">
          {isVisible ? (
            <EyeIcon className="h-5" onClick={() => setIsVisible(false)} />
          ) : (
            <EyeSlashIcon className="h-5" onClick={() => setIsVisible(true)} />
          )}
        </div>
      </div>
      <div className="flex flex-row relative w-80">
        <Input
          errorMsg={errors?.confirmPassword?.message}
          inputClass={errors?.confirmPassword?.message && "ring-error ring-1"}
          className="w-80"
          label={t("welcome.confirm_password")}
          placeholder={t("signup.enter_password")}
          type={isVisibleConfirm ? "text" : "password"}
          required
          {...register("confirmPassword", {
            required: t("signup.is_required_field_error", {
              field: t("welcome.confirm_password")?.toLowerCase(),
            }),
            maxLength: 64,
            validate: (value) => {
              if (value !== watch("password"))
                return t("signup.confirm_password_error");
            },
          })}
        />{" "}
        <div className="absolute right-2 top-7 cursor-pointer text-primary">
          {isVisibleConfirm ? (
            <EyeIcon
              className="h-5"
              onClick={() => setIsVisibleConfirm(false)}
            />
          ) : (
            <EyeSlashIcon
              className="h-5"
              onClick={() => setIsVisibleConfirm(true)}
            />
          )}
        </div>
      </div>
      <center>
        <Button
          loading={loading}
          type="submit"
          color="primary"
          text={t("buttons.save")}
          className="mt-2"
        />
      </center>
    </form>
  );
};

export default PasswordForm;
