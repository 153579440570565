import bilAPI from "../api";

export const getSubscriptionPlans = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/subscription_plans/`, {
          params: { page_size: 200 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const getSubscriptionPlanById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/subscription_plans/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const postSubscriptionPlan = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/payment/subscription_plans/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchSubscriptionPlan = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/payment/subscription_plans/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSubscriptionPlan = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/payment/subscription_plans/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
