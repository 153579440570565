import React from "react";

export default function DotsGrid() {
  return (
    <div>
      <svg width="64" height="164" xmlns="http://www.w3.org/2000/svg">
        <g fill="black">
          <circle cx="6" cy="6" r="3" />
          <circle cx="6" cy="24" r="3" />
          <circle cx="6" cy="42" r="3" />
          <circle cx="6" cy="60" r="3" />
          <circle cx="6" cy="78" r="3" />
          <circle cx="6" cy="96" r="3" />
          <circle cx="6" cy="114" r="3" />
          <circle cx="6" cy="132" r="3" />
          <circle cx="6" cy="150" r="3" />
          <circle cx="6" cy="168" r="3" />

          <circle cx="22" cy="6" r="3" />
          <circle cx="22" cy="24" r="3" />
          <circle cx="22" cy="42" r="3" />
          <circle cx="22" cy="60" r="3" />
          <circle cx="22" cy="78" r="3" />
          <circle cx="22" cy="96" r="3" />
          <circle cx="22" cy="114" r="3" />
          <circle cx="22" cy="132" r="3" />
          <circle cx="22" cy="150" r="3" />
          <circle cx="22" cy="168" r="3" />

          <circle cx="38" cy="6" r="3" />
          <circle cx="38" cy="24" r="3" />
          <circle cx="38" cy="42" r="3" />
          <circle cx="38" cy="60" r="3" />
          <circle cx="38" cy="78" r="3" />
          <circle cx="38" cy="96" r="3" />
          <circle cx="38" cy="114" r="3" />
          <circle cx="38" cy="132" r="3" />
          <circle cx="38" cy="150" r="3" />
          <circle cx="38" cy="168" r="3" />

          <circle cx="54" cy="6" r="3" />
          <circle cx="54" cy="24" r="3" />
          <circle cx="54" cy="42" r="3" />
          <circle cx="54" cy="60" r="3" />
          <circle cx="54" cy="78" r="3" />
          <circle cx="54" cy="96" r="3" />
          <circle cx="54" cy="114" r="3" />
          <circle cx="54" cy="132" r="3" />
          <circle cx="54" cy="150" r="3" />
          <circle cx="54" cy="168" r="3" />
        </g>
      </svg>
    </div>
  );
}
