import React from "react";
import { Button } from "../../../../../components/forms/Buttons/Button";
import { deleteExam } from "../../../../../services/tests/examsService";
import useSnackbar from "../../../../../utils/hooks/useSnackbar";
import { CheckIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Delete = ({ exam, getData, isDeleteMode, setIsDeleteMode }) => {
  const { addSnackbar } = useSnackbar();

  const deleteExamHandler = () => {
    deleteExam(exam?.uuid)?.finally(() => {
      getData();
      addSnackbar("Экзамен был успешно удален", "success");
    });
  };
  return (
    <>
      {isDeleteMode && (
        <Button
          Icon={CheckIcon}
          className="rounded-r-none"
          color="error-solid"
          onClick={() => {
            deleteExamHandler();
            setIsDeleteMode(false);
          }}
        />
      )}
      <Button
        Icon={isDeleteMode ? XMarkIcon : TrashIcon}
        className="rounded-l-none"
        color={isDeleteMode ? "primary-solid" : "error"}
        onClick={() => {
          setIsDeleteMode((v) => !v);
        }}
      />
    </>
  );
};

export default Delete;
