import React, { useState, useCallback } from "react";
import Snackbar from "../../components/Snackbar/Snackbar";

const AUTO_DISMISS = 5000;

export const SnackbarContext = React.createContext({
  toast: null,
  addSnackbar: () => {},
  removeSnackbar: () => {},
});

export default function SnackbarProvider({ children }) {
  const [toast, setSnackbar] = useState(null);

  const removeSnackbar = () => setSnackbar(null);

  const addSnackbar = (message, type, duration = AUTO_DISMISS) => {
    setSnackbar({ message, type, duration });
    setTimeout(() => {
      removeSnackbar();
    }, duration);
  };

  const contextValue = {
    toast,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    addSnackbar: useCallback(
      (message, type, duration) => addSnackbar(message, type, duration),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    removeSnackbar: useCallback(() => removeSnackbar(), []),
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      {toast && (
        <div className="h-full">
          <Snackbar
            duration={toast?.duration}
            removeSnackbar={removeSnackbar}
            message={toast?.message}
            type={toast?.type}
          />
        </div>
      )}
    </SnackbarContext.Provider>
  );
}
