import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../utils/contexts/UserContext";
import MainInfo from "./StudentTabs/MainInfo";

export default function StudentProfile() {
  const { user } = useContext(UserContext);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (user) {
      setProfile(user);
    }

    return () => {};
  }, [user]);

  return (
    <div className="space-y-10 px-4 py-8">
      <MainInfo profile={profile} />
    </div>
  );
}
