import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../../components/forms/Buttons/Button";
import { formatDate } from "../../../../utils/helpers/helpers";

const ExamCard = ({ exam, order }) => {
  const [t] = useTranslation();

  return (
    <div className="flex w-full flex-col ">
      <div className="grid grid-cols-1 place-items-center justify-start gap-5 sm:grid-cols-2 sm:place-items-stretch lg:grid-cols-3 xl:grid-cols-4 relative">
        {!exam?.is_available && (
          <div className="absolute top-1/4 p-2 bg-white text-center rounded-lg border flex flex-col gap-2 z-10">
            <span>{t("payment.buy_subscription_alert")}</span>
            <Link to={`/student/subscriptions`}>
              <Button className="mt-2 max-w-fit" text={t("payment.buy")} />
            </Link>
          </div>
        )}
        <div
          className={`flex  w-[18rem] flex-col rounded-lg border ${
            !exam?.is_available && "blur-sm select-none"
          }`}
          key={exam?.uuid}
        >
          <p className="bg-slate-200 py-3 px-5">
            {order + 1}. {exam?.name}
          </p>
          <div className="flex h-full flex-col items-start justify-start p-5">
            <p className="mb-5">
              {t("mockup.option")}: {exam?.variant}
            </p>
            <p>
              {t("mockup.duration")}: {exam?.duration}
            </p>
            <p>
              {t("mockup.num_of_questions")}: {exam?.questions_count}
            </p>
            {!!exam?.result ? (
              <>
                <p>
                  {t("mockup.num_of_correct")}: {exam?.result?.total_correct}
                </p>
                <p>
                  {t("mockup.num_of_wrong")}: {exam?.result?.total_wrong}
                </p>
                <p>
                  {t("mockup.num_of_unmarked")}: {exam?.result?.total_no_answer}
                </p>
                <p>
                  {t("mockup.total")}: {exam?.result?.result}
                </p>
                <Link to={`/student/exam/${exam?.uuid}/result`}>
                  <Button
                    className="mt-2 max-w-fit"
                    color="success"
                    text={t("student.results")}
                  />
                </Link>
              </>
            ) : !exam?.result &&
              !exam?.attempt?.is_in_progress &&
              !!exam?.attempt ? (
              <>
                <p>
                  {t("mockup.num_of_correct")}: {0}
                </p>
                <p>
                  {t("mockup.num_of_wrong")}: {0}
                </p>
                <p>
                  {t("mockup.num_of_unmarked")}: {exam?.questions_count}
                </p>
                <p>
                  {t("mockup.total")}: {0}
                </p>
                <Link to={`/student/exam/${exam?.uuid}/result`}>
                  <Button
                    className="mt-2 max-w-fit"
                    color="success"
                    text={t("student.results")}
                  />
                </Link>
              </>
            ) : (
              <Link to={`/student/exam/${exam?.uuid}`}>
                <Button
                  disabled={!exam?.is_available}
                  className="mt-2 max-w-fit"
                  text={t(
                    !!exam?.attempt ? "buttons.continue" : "buttons.begin"
                  )}
                  color={!!exam?.attempt && "success"}
                />
              </Link>
            )}
          </div>
          <p className="bg-slate-200 py-3 px-5 text-xs">{`${t(
            "mockup.finished_time"
          )}: ${
            exam?.result
              ? formatDate(exam?.result?.created)
              : t("mockup.not_passed")
          }`}</p>
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
