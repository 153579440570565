import bilAPI from "../api";

export const postRegistration = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/auth/registration/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getConfirm = ({ token }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/registration/confirm/${token}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
