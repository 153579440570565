import React, { useRef } from "react";
import { useState } from "react";
import { Button } from "../../../components/forms/Buttons/Button";
import Select from "../../../components/forms/Select/Select";
import CustomDialog from "../../../components/modals/CustomDialog/CustomDialog";
import {
  ANSWER_OPTIONS,
  QUESTIONS_TYPES,
} from "../../../utils/constants/constants";
import useSnackbar from "../../../utils/hooks/useSnackbar";
import { CameraIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { patchQuestion } from "../../../services/tests/questionService";

const Edit = ({ question, questionId, getData }) => {
  const { addSnackbar } = useSnackbar();

  const [image, setImage] = useState(question?.image);
  const [imageFile, setImageFile] = useState();
  const [type, setType] = useState(question?.type);
  const [answer, setAnswer] = useState(question?.correct_answer);
  const [open, setOpen] = useState(false);
  const fileRef = useRef();

  const updateQuestionHandler = () => {
    const Body = new FormData();

    if (imageFile) Body.append("image", imageFile);
    if (question?.type !== type) Body.append("type", type);
    if (question?.correct_answer !== answer)
      Body.append("correct_answer", answer);
    setOpen(false);

    patchQuestion(question?.uuid, Body)?.finally(() => {
      addSnackbar("Вопрос успешно обновлен", "success");
      getData();
    });
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files[0].type.substring(0, 5) !== "image") {
      addSnackbar(
        "Жүктелген файл сурет болуы керек! / Загружаемый вами файл должен быть изображением!",
        "error"
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      addSnackbar(
        "Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ",
        "error"
      );
      return;
    } else {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);

      setImageFile(file);
    }
  };

  return (
    <>
      <Button
        Icon={PencilSquareIcon}
        className="rounded-r-none"
        onClick={() => {
          setOpen(true);
        }}
      />

      <CustomDialog
        isOpen={open}
        headerText={`Вопрос #${questionId}`}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col">
            <div className="flex flex-col ">
              <label className="block text-sm font-medium text-gray-700">
                Тип
                <span className={`text-error`}>{"*"}</span>
              </label>
              <Select
                value={type}
                onChange={(v) => {
                  setType(v?.value);
                }}
                options={QUESTIONS_TYPES?.map((type, i) => ({
                  label: type,
                  value: i,
                }))}
              />

              <center className="my-2">
                {image ? (
                  <img
                    className="img-responsive rounded-lg"
                    alt="question"
                    src={image}
                  />
                ) : (
                  <div className="flex h-52 w-full flex-col justify-center rounded-lg bg-yellow-300 text-center">
                    Нет загруженного изображения
                  </div>
                )}
              </center>
              <div className="flex flex-row justify-end">
                <Button
                  className="max-w-fit"
                  Icon={CameraIcon}
                  text="Загрузить"
                  color="primary"
                  onClick={() => fileRef.current.click()}
                />
              </div>
              <input
                hidden
                type="file"
                ref={fileRef}
                onChange={handleImageChange}
              />
              <div className="flex flex-row justify-center gap-10">
                {ANSWER_OPTIONS?.map((option) => (
                  <div
                    className="flex flex-row items-center gap-1"
                    key={option}
                  >
                    <input
                      type="radio"
                      key={option}
                      checked={answer === option}
                      onChange={() => setAnswer(option)}
                    />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              text="Отмена"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            />
            <Button
              text="Сохранить"
              color="success"
              onClick={() => updateQuestionHandler()}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default Edit;
