const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshTkn");
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem("accessTkn");
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem("accessTkn", token);
};

const updateLocalRefreshToken = (token) => {
  localStorage.setItem("refreshTkn", token);
};

const removeTokens = () => {
  localStorage.removeItem("refreshTkn");
  localStorage.removeItem("accessTkn");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  removeTokens,
};

export default TokenService;
