export const BASE_URL = process.env.REACT_APP_API_URL;

export const EXAMTYPES = ["Пробный 1", "Пробный 2", "Онлайн экзамен"]; //, '1 тур', '2 тур'];
export const QUESTIONS_TYPES = [
  "Математика",
  "Грамотность чтения 1 (KZ)",
  "Грамотность чтения 2 (KZ)",
  "Грамотность чтения 1 (RU)",
  "Грамотность чтения 2 (RU)",
];
export const ANSWER_OPTIONS = ["A", "B", "C", "D", "E"];

export const REGISTER_ERROR_CODE = { 1: "signup.email_is_taken" };
export const PASSWORD_RESET_ERROR_CODE = {
  1: "signup.invalid_or_expired_token",
  2: "Token is valid, but password not provided",
  3: "signup.password_reset_err.current_password_not_correct",
  4: "signup.password_reset_err.new_password_same_to_current",
};
