import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/forms/Buttons/Button";
import CustomDialog from "../../../../../components/modals/CustomDialog/CustomDialog";

const ExamTextsButton = ({ exam, user }) => {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className="col-span-2 max-w-fit"
        color="success"
        text={t("mockup.literacy_reading_header")}
        onClick={() => setOpen(true)}
      />
      <CustomDialog
        isOpen={open}
        headerText={t("mockup.literacy_reading_header")}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className="flex flex-col gap-8">
          <div>
            <p className="text-lg ">{t("mockup.literacy_reading_text_1")}</p>
            <img
              className="mt-2 rounded-lg"
              src={
                user?.application?.language === "R"
                  ? exam?.rus_1_text?.image
                  : exam?.kaz_1_text?.image
              }
              alt="text 1"
            />
          </div>
          <hr />
          <div>
            <p className="text-lg ">{t("mockup.literacy_reading_text_2")}</p>

            <img
              className="mt-2 rounded-lg"
              src={
                user?.application?.language === "R"
                  ? exam?.rus_2_text?.image
                  : exam?.kaz_2_text?.image
              }
              alt="text 2"
            />
          </div>
        </div>

        <div className="mt-2 flex flex-row justify-end">
          <Button
            text={t("buttons.close")}
            color="secondary"
            onClick={() => setOpen(false)}
          />
        </div>
      </CustomDialog>
    </>
  );
};

export default ExamTextsButton;
