import React, { useCallback, useEffect, useState } from "react";
import { getResults } from "../../../services/tests/resultExamService";
import Select from "../../../components/forms/Select/Select";
import Table from "../../../components/Table/Table";
import { getExams } from "../../../services/tests/examsService";

export default function ResultsStatistics() {
  const [results, setResults] = useState([]);
  const [tests, setTests] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const PAGE_SIZE = 50;
  const [test, setTest] = useState(null);

  const nextPage = () => {
    setLoading(true);
    getResultsData({ page: page + 1, PAGE_SIZE });

    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getResultsData({ page: page - 1, PAGE_SIZE });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getResultsData({ page: pageNum, PAGE_SIZE });
    setPage((v) => v - 1);
  };

  const getResultsData = useCallback(
    ({ page = 1, pageSize = PAGE_SIZE }) => {
      setLoading(true);

      getResults({ page, page_size: pageSize, test })
        .then((res) => {
          setResults(res.results);
          setCount(res.count);
          setPageCount(Math.ceil(res.count / PAGE_SIZE));
          setPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [test]
  );

  const getTestsData = useCallback(() => {
    getExams({ page_size: 100 }).then((res) => {
      setTests(
        res.results?.map((t) => ({
          value: t.uuid,
          label: `${t.name} - var. ${t.variant}`,
        }))
      );
    });
  }, []);

  useEffect(() => {
    getResultsData({});
    getTestsData();
  }, [getResultsData, getTestsData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        Cell: (props) => {
          return (
            <span className="text-center">
              {props.row.index + 1 + (page - 1) * PAGE_SIZE}
            </span>
          );
        },
      },
      {
        Header: "Username",
        accessor: "user.username",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: "Имя",
        accessor: "user.first_name",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: "Фамилия",
        accessor: "user.last_name",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: "Правильные",
        accessor: "total_correct",
        Cell: (props) => (
          <div className="px-2">
            {props.value > 0 ? (
              <span className="font-bold text-green-600">{props.value}</span>
            ) : (
              <span className="">{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: "Неправильные",
        accessor: "total_wrong",
        Cell: (props) => (
          <div className="px-2">
            {props.value > 0 ? (
              <span className="font-bold text-red-600">{props.value}</span>
            ) : (
              <span className="">{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: "Без ответа",
        accessor: "total_no_answer",
        Cell: (props) => (
          <div className="px-2">
            {props.value > 0 ? (
              <span className="font-bold text-gray-500">{props.value}</span>
            ) : (
              <span className="">{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: "Общий итог",
        accessor: "result",
        Cell: (props) => (
          <div className="px-2">
            <span className="font-bold">{props.value}</span>
          </div>
        ),
      },
      {
        Header: "Общий итог",
        accessor: "test.variant",
        Cell: (props) => (
          <div className="px-2">
            <span className="font-bold">{props.value}</span>
          </div>
        ),
      },
    ],
    [page]
  );

  return (
    <div className="flex flex-col gap-2">
      <div>
        <Select
          className="h-6 w-48"
          placeholder={"Номер экзамена"}
          options={[{ label: "All", value: null }, ...tests]}
          // label={'Номер экзамена'}
          value={test}
          onChange={(e) => {
            setLoading(true);

            setTest(e.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        data={results}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
    </div>
  );
}
