import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleLoader from "../../../components/Loader/SimpleLoader";
import { getSubscriptionPlans } from "../../../services/payment/subscriptionPlanService";
import PlanCard from "./PlanCard";
// import ExamCard from "./ExamCard";

const SubscriptionPlansStudent = () => {
  const [t] = useTranslation();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSubscriptionPlansData();
  }, []);

  const getSubscriptionPlansData = () => {
    setIsLoading(true);
    getSubscriptionPlans()
      .then((res) => {
        // console.log(res.results);
        setSubscriptionPlans(res.results);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="flex w-full flex-col ">
      {isLoading ? (
        <SimpleLoader className="h-10" />
      ) : (
        <div className="grid grid-cols-1 place-items-center justify-start gap-5 sm:grid-cols-2 sm:place-items-stretch lg:grid-cols-3 xl:grid-cols-4 ">
          {subscriptionPlans?.length ? (
            subscriptionPlans?.map((plan, i) => {
              return <PlanCard plan={plan} key={plan?.uuid} />;
            })
          ) : (
            <div className="col-span-1 flex flex-col items-center sm:col-span-2  lg:col-span-3 xl:col-span-4">
              <div className=" rounded-md bg-yellow-200 p-4">
                <p className="text-2xl font-semibold">
                  {t("mockup.exams_is_not_avaible")}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlansStudent;
