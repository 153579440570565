import { Navigate, Outlet } from "react-router-dom";
import SimpleLoader from "../../Loader/SimpleLoader";

export default function ProtectedRoute({
  isAllowed,
  isLoading,
  redirectPath = "/login",
  children,
}) {
  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <SimpleLoader className="h-12" />
      </div>
    );
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}
