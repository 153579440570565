import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/forms/Buttons/Button";
import ExamTextsButton from "../../Exam/Student/texts/ExamTextsButton";

const ExaminationForm = ({
  exam,
  user,
  question,
  setQuestionByIndex,
  questionIndex,
  questionLenght,
}) => {
  const [t] = useTranslation();

  return (
    <div className="col-span-9 flex w-full flex-row justify-center">
      {question?.uuid ? (
        <div className="flex flex-col items-center gap-5">
          <div className="flex w-full flex-row justify-between gap-5">
            <div className="flex flex-col items-center gap-3">
              <span className="text-lg sm:text-xl ">
                {t("results.correct_answer")}:
              </span>
              <span className="text-xl font-semibold sm:text-3xl">
                {question?.correct_answer}
              </span>
            </div>
            <div className="flex flex-col items-center gap-3">
              <span className="text-lg sm:text-xl ">
                {t("results.your_answer")}:
              </span>
              <span className="text-xl font-semibold sm:text-3xl">
                {question?.your_answer}
              </span>
            </div>
          </div>
          <hr />
          <div
            className={
              "grid grid-cols-2 place-items-center sm:invisible " +
              (question?.type === 0 && " invisible")
            }
          >
            <ExamTextsButton exam={exam} user={user} />
          </div>
          <img
            className="img-responsive rounded-lg"
            alt="question"
            src={question?.image}
          />
          <div className="flex w-full flex-row justify-between">
            <Button
              className={!(questionIndex > 0) && "invisible"}
              //   text={t('buttons.prev')}
              text={t("buttons.back")}
              onClick={() => setQuestionByIndex(questionIndex - 1)}
            />
            <Button
              className={!(questionIndex < questionLenght - 1) && "invisible"}
              text={t("buttons.next")}
              onClick={() => setQuestionByIndex(questionIndex + 1)}
            />
          </div>
        </div>
      ) : (
        <div className="items-centerw-full flex w-full flex-col">
          <div className="w-10/12  rounded-md bg-yellow-200 p-4">
            {t("mockup.question_not_choosed")}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExaminationForm;
