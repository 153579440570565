import bilAPI from "../api";
import examAPI from "../examAPI";

export const postResult = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .post(`/tests/complete_test/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getResults = ({ test, page_size, page }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/tests/user_results/`, { params: { test, page_size, page } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
