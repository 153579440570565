import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../../../components/forms/Buttons/Button";
import CustomDialog from "../../../../../components/modals/CustomDialog/CustomDialog";
import { postExam } from "../../../../../services/tests/examsService";
import Input from "../../../../../components/forms/Input/Input";
import useSnackbar from "../../../../../utils/hooks/useSnackbar";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import ToggleButton from "../../../../../components/forms/Buttons/ToggleButton";

const Add = ({ getData }) => {
  const { addSnackbar } = useSnackbar();

  const { register, handleSubmit, reset, control } = useForm();
  const [open, setOpen] = useState(false);

  const createExamHandler = (data) => {
    if (data?.type == null) {
      data.type = 0;
    }

    if (!Number.isInteger(data?.duration / 1)) {
      addSnackbar("Длительность экзамена должно быть числом", "error");
      return;
    }
    setOpen(false);

    postExam(data)?.finally(() => {
      getData();
      addSnackbar("Экзамен успешно добавлен", "success");
    });
    reset();
  };

  return (
    <>
      <Button
        text="Добавить"
        Icon={PlusSmallIcon}
        color="primary"
        onClick={() => {
          reset();
          setOpen(true);
        }}
      />

      <CustomDialog
        isOpen={open}
        headerText="Подтвердите ваши действие"
        closeModal={() => {
          setOpen(false);
          reset();
        }}
      >
        <form
          onSubmit={handleSubmit(createExamHandler)}
          className="flex flex-col gap-2"
        >
          <div className="flex flex-col">
            <Input
              label="Название"
              inputClass="border"
              {...register("name", { required: true })}
              required
            />

            <Input
              label="Длительность экзамена"
              inputClass="border"
              {...register("duration", { required: true })}
              required
            />
            <Input
              label="Вариант"
              inputClass="border"
              {...register("variant", { required: true })}
              required
            />
            <div className="mt-2 flex flex-row items-center gap-2">
              <label>Is free</label>

              <Controller
                control={control}
                name="is_free"
                render={({ field }) => (
                  <ToggleButton
                    value={field.value}
                    onClick={() => field.onChange(!field.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              text="Отмена"
              color="secondary"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            />
            <Button text="Создать" color="success" type="submit" />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;
