import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "../../../components/forms/Select/Select";
import Table from "../../../components/Table/Table";
import { getOrders } from "../../../services/payment/orderService";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { formatTimeDate } from "../../../utils/helpers/helpers";

export default function OrderStatistics() {
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const PAGE_SIZE = 50;
  const [status, setStatus] = useState(null);
  const nameRef = useRef();
  const txnRef = useRef();
  const [name, setName] = useState("");
  const [txn, setTxn] = useState("");

  const nextPage = () => {
    setLoading(true);
    getOrdersData({ page: page + 1, PAGE_SIZE });

    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getOrdersData({ page: page - 1, PAGE_SIZE });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getOrdersData({ page: pageNum, PAGE_SIZE });
    setPage((v) => v - 1);
  };

  const getOrdersData = useCallback(
    ({ page = 1, pageSize = PAGE_SIZE }) => {
      setLoading(true);

      getOrders({ page, page_size: pageSize, status, txn_id: txn, name })
        .then((res) => {
          setOrders(res.results);
          setCount(res.count);
          setPageCount(Math.ceil(res.count / PAGE_SIZE));
          setPage(1);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [name, status, txn]
  );

  useEffect(() => {
    getOrdersData({});
  }, [getOrdersData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "index",
        Cell: (props) => {
          return (
            <span className="text-center">
              {props.row.index + 1 + (page - 1) * PAGE_SIZE}
            </span>
          );
        },
      },
      {
        Header: "Username",
        accessor: "user.username",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: "Имя",
        accessor: "user.first_name",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: "Фамилия",
        accessor: "user.last_name",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: "order id",
        accessor: "order_id",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique order_id`,
      },
      {
        Header: "bank txn id",
        accessor: "bank_txn_id",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Unique bank_txn_id`,
      },
      {
        Header: "txn_date",
        accessor: "txn_date",
        Cell: (props) => (
          <div className="px-2">
            {props.value ? formatTimeDate(props.value) : ""}
          </div>
        ),
      },
    ],
    [page]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2">
        <Select
          className="h-6 w-48"
          placeholder={"Статус"}
          options={[
            { label: "All", value: null },
            { label: "Оплачен", value: 1 },
            { label: "Не оплачен", value: 0 },
          ]}
          // label={'Номер экзамена'}
          value={status}
          onChange={(e) => {
            setLoading(true);
            setStatus(e.value);
          }}
        />
        <div className="flex flex-col group relative ">
          <input
            required
            ref={nameRef}
            className="block w-full rounded-md border-gray-300 px-3 py-2 pr-12 shadow-sm disabled:bg-slate-200 text-sm peer"
          />

          <label
            className="text-sm top-2 mx-3 absolute transition-all delay-100 cursor-text
              group-focus-within:-top-5 group-focus-within:text-xs group-focus-within:mx-0
              peer-valid:-top-5 peer-valid:text-xs peer-valid:mx-0"
          >
            {"Name"}
            {/* <span className="text-error text-end"> *</span> */}
          </label>
          <div className="absolute right-2 bottom-2.5 cursor-pointer text-primary">
            <MagnifyingGlassIcon
              className="h-5"
              onClick={() => setName(nameRef?.current?.value)}
            />
          </div>
        </div>
        <div className="flex flex-col group relative ">
          <input
            required
            ref={txnRef}
            className="block w-full rounded-md border-gray-300 px-3 py-2 pr-12 shadow-sm disabled:bg-slate-200 text-sm peer"
          />

          <label
            className="text-sm top-2 mx-3 absolute transition-all delay-100 cursor-text
              group-focus-within:-top-5 group-focus-within:text-xs group-focus-within:mx-0
              peer-valid:-top-5 peer-valid:text-xs peer-valid:mx-0"
          >
            {"Order / txn id"}
            {/* <span className="text-error text-end"> *</span> */}
          </label>
          <div className="absolute right-2 bottom-2.5 cursor-pointer text-primary">
            <MagnifyingGlassIcon
              className="h-5"
              onClick={() => setTxn(txnRef?.current?.value)}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={orders}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
    </div>
  );
}
