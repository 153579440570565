import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../../components/forms/Select/Select";
import { landingSelect } from "../../components/forms/Select/styles";
import { languages } from "../../components/navigation/PublicNavBar/PublicNavBar";
import { Button } from "../../components/forms/Buttons/Button";
import { Link } from "react-router-dom";
import bilim_logo_kz from "./images/bilim_logo_kz.png";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import DotsGrid from "./images/DotsGrid";
import Frame from "./components/Frame";

export default function Landing() {
  const [t, i18n] = useTranslation();

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <div className="flex h-screen w-screen flex-col gap-4  bg-no-repeat overflow-hidden">
      <div className="hidden flex-col lg:flex">
        <div className="py-4 px-24 bg-white flex flex-row items-center justify-between">
          <img
            src={i18n.language === "ru" ? bilim_logo_kz : bilim_logo_kz}
            alt="Bil logo"
            className="h-[2.1rem] w-[9.688rem]"
          />
          <div className="flex flex-row items-center gap-4">
            <Select
              className="w-40 text-center"
              customStyles={landingSelect}
              isSearchable={false}
              value={i18n.language}
              options={[
                ...languages?.map((o) => ({
                  label: o?.label,
                  value: o?.value,
                })),
              ]}
              onChange={(e) => handleLanguageChange(e.value)}
            />
            <Link to="login">
              <Button
                className="rounded-xl"
                color="landing-purple"
                text={t("welcome.mockup_exam")}
              />
            </Link>
          </div>
        </div>
        <div className="bg-gray-landing h-[calc(100vh_-_4.5rem)] w-screen flex flex-row justify-between gap-8">
          <div className="flex flex-col w-3/5 justify-center items-center">
            <div className="flex flex-col w-[44rem] gap-8">
              <h1 className="text-5xl font-bold text-wrap">
                {t("welcome.title")}
              </h1>
              <p>{t("welcome.desc")}</p>
              <div>
                <Link to="login">
                  <Button
                    className="rounded-xl w-64 h-16 text-xl flex-row-reverse"
                    color="landing-purple"
                    text={t("welcome.mockup_exam")}
                    Icon={ChevronRightIcon}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="w-2/5 max-w-xl flex flex-col relative">
            <div className="absolute top-4 left-8">
              <svg
                width="200"
                height="200"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="40" fill="#00A97D" />
                <circle cx="50" cy="50" r="20" fill="#F7F1FD" />
              </svg>
            </div>

            <div className="absolute top-0 right-0">
              <svg width="12rem" height="12rem" viewBox="0 0 100 100">
                <g transform="rotate(270, 50, 50)">
                  <path
                    d="M 0 100 A 100 100 0 0 1 100 0 L 100 100 Z"
                    fill="#FF8154"
                  />
                </g>
              </svg>
            </div>
            <div className="absolute bottom-0 left-0">
              <svg width="12rem" height="12rem" viewBox="0 0 100 100">
                <g transform="rotate(90, 50, 50)">
                  <path
                    d="M 0 100 A 100 100 0 0 1 100 0 L 100 100 Z"
                    fill="#813BE2"
                  />
                </g>
              </svg>
            </div>
            <div className="absolute bottom-0 -right-4">
              <svg width="16rem" height="16rem" viewBox="0 0 100 100">
                <g transform="rotate(90, 50, 50)">
                  <path
                    d="M 0 100 A 100 100 0 0 1 100 0 L 100 100 Z"
                    fill="#FED151"
                  />
                </g>
              </svg>
            </div>

            <div className="absolute top-1/3">
              <DotsGrid />
            </div>

            <div className="absolute top-1/2 -right-4">
              <DotsGrid />
            </div>
            <div className="absolute top-32">
              <Frame />
            </div>

            {/* circle */}
            {/* <svg
              className="-translate-x-8 -translate-y-24"
              width="162"
              height="162"
              viewBox="0 0 162 162"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M98.0453 80.8104C98.0453 90.3987 90.2841 98.1208 80.7727 98.1208C71.2612 98.1208 63.5 90.3987 63.5 80.8104C63.5 71.2222 71.2612 63.5 80.7727 63.5C90.2841 63.5 98.0453 71.2222 98.0453 80.8104Z"
                stroke="white"
                strokeWidth="127"
              />
            </svg> */}
          </div>
        </div>
      </div>
      {/*Mob version start
      
      
      
      */}
      <div className="pt-8 flex flex-col gap-2 lg:hidden h-screen bg-gray-landing">
        <div className="flex flex-row justify-between px-4">
          {/* <img src={mob_logo} alt='logo' width={49} height={43} /> */}
          <img
            src={i18n.language === "ru" ? bilim_logo_kz : bilim_logo_kz}
            alt="Bil logo"
            className=" h-6"
          />
          <Select
            className="z-30 w-48 text-center"
            customStyles={landingSelect}
            isSearchable={false}
            value={i18n.language}
            options={[
              ...languages?.map((o) => ({
                label: o?.label,
                value: o?.value,
              })),
            ]}
            onChange={(e) => handleLanguageChange(e.value)}
          />
        </div>
        <div className="flex flex-row justify-end px-4">
          <Link to="login">
            <Button
              className="w-48 rounded-xl"
              color="landing-purple"
              text={t("welcome.mockup_exam")}
            />
          </Link>
        </div>
        <div className="flex flex-row items-end justify-between px-8 mt-12">
          <div className="flex flex-row">
            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-8">
                <h1 className="text-5xl font-bold text-wrap">
                  {t("welcome.title")}
                </h1>
                <p>{t("welcome.desc")}</p>
                <div>
                  <Link to="login">
                    <Button
                      className="rounded-xl w-64 h-16 text-xl flex-row-reverse"
                      color="landing-purple"
                      text={t("welcome.mockup_exam")}
                      Icon={ChevronRightIcon}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>{/* не трогать это важная заглушка */}</div>
      </div>
    </div>
  );
}
