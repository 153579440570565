import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SimpleLoader from "../../../components/Loader/SimpleLoader";
import { getOrderById } from "../../../services/payment/orderService";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/forms/Buttons/Button";

const StudentOrder = () => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const [order, setOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const { addSnackbar } = useSnackbar();

  const getOrder = useCallback(() => {
    setIsLoading(true);
    getOrderById(uuid)
      ?.then((res) => {
        setOrder(res);
      })
      ?.finally(() => {
        setIsLoading(false);
      });
  }, [uuid]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return (
    <div className="flex w-full flex-col gap-2">
      {isLoading ? (
        <SimpleLoader className="h-5" />
      ) : (
        <>
          <center>
            {order ? (
              <div className="border rounded-lg p-3 w-80 bg-slate-200 flex flex-col gap-2 items-center">
                <span>
                  {t("payment.order_number")}: {order.order_id}
                </span>
                <img className="w-60" src={order?.kaspi_qr} alt="Kaspi qr" />

                <form
                  action="https://kaspi.kz/online"
                  method="post"
                  id="kaspikz-form"
                  className="flex flex-col gap-2"
                >
                  <input
                    type="hidden"
                    name="TranId"
                    value={`${order?.order_id}`}
                  />
                  <input
                    type="hidden"
                    name="OrderId"
                    value={`${order?.order_id}`}
                  />
                  <input type="hidden" name="Amount" value={order?.sum * 100} />
                  <input
                    type="hidden"
                    name="Service"
                    value="biliminnovaciyaOnline"
                  />
                  <input
                    type="hidden"
                    name="returnUrl"
                    value={window.location.href}
                  />

                  <div className="flex flex-row justify-center">
                    <Button
                      type="submit"
                      text={t("payment.pay_in_kaspi")}
                      className="w-60"
                    />
                  </div>
                  <div className="flex flex-row justify-center">
                    <Button
                      text={t("payment.update")}
                      type="button"
                      onClick={getOrder}
                      className="w-60 h-fit"
                    />
                  </div>
                  <p className="flex flex-col">
                    <span>{t("payment.order_status")}: </span>
                    {order.status === 1 ? (
                      <span className="text-green-500">
                        {t("payment.paid")}
                      </span>
                    ) : (
                      <span className="text-red-500">
                        {t("payment.not_paid")}
                      </span>
                    )}
                  </p>
                </form>
              </div>
            ) : (
              <div>404</div>
            )}
          </center>
        </>
      )}
    </div>
  );
};

export default StudentOrder;
