import bilAPI from "../api";

export const getOrderById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/orders/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const getOrders = ({ page, page_size, status, txn_id, name }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/orders/`, {
          params: { page, page_size, status, txn_id, name },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const postOrder = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/payment/orders/create/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
