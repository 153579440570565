// import React from "react";
// import { Document, Page } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// export function showImageOrPdf(docUrl, heightOfDoc) {
//   if (docUrl?.substr(docUrl?.length - 4) === ".pdf") {
//     return (
//       <div className={""}>
//         <Document file={docUrl}>
//           <Page
//             pageNumber={1}
//             renderTextLayer={false}
//             renderAnnotationLayer={false}
//             height={heightOfDoc * 16}
//           />
//         </Document>
//       </div>
//     );
//   }

//   if (docUrl) {
//     return (
//       <React.Fragment>
//         <div className={""}>
//           <Document file={docUrl}>
//             <Page
//               pageNumber={1}
//               renderTextLayer={false}
//               renderAnnotationLayer={false}
//               height={heightOfDoc * 16}
//             />
//           </Document>
//         </div>

//         <img className="h-[8rem] object-cover" src={docUrl} alt="doc" />
//       </React.Fragment>
//     );
//   }

//   return <div className="italic">no image</div>;
// }

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const formatTimeDate = (date) => {
  const getUTC = () => {
    const match = date.match(/([+-]\d{2}:\d{2})$/);
    return match ? match[0] : null;
  };

  const getTimeWithoutUTC = (d) => {
    return d.replace(/([+-]\d{2}:\d{2})$/, "");
  };

  const utc = getUTC();
  if (utc) {
    date = getTimeWithoutUTC(date);
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return `${[hour, minute, second].join(":")} - ${[day, month, year].join(
    "/"
  )} (${utc})`;
};
