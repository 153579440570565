import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/forms/Buttons/Button";
import Input from "../../../components/forms/Input/Input";
import { UserContext } from "../../../utils/contexts/UserContext";
import { postRegistration } from "../../../services/auth/registrationService";
import Select from "../../../components/forms/Select/Select";
import useSnackbar from "../../../utils/hooks/useSnackbar";
import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/outline";
import { REGISTER_ERROR_CODE } from "../../../utils/constants/constants";

export default function Registration() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
    control,
  } = useForm();

  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    await postRegistration(data)
      .then(() => {
        setIsComplete(true);
      })
      .catch((e) => {
        const errorCode = e?.response?.data?.code;
        if (errorCode in REGISTER_ERROR_CODE) {
          addSnackbar(t(REGISTER_ERROR_CODE[errorCode]), "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?.uuid) {
      if (user?.roles?.some((role) => role?.code === "ADMIN")) {
        navigate({
          pathname: `/admin/exams`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "VP")) {
        navigate({
          pathname: `/applicants`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "STD")) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
      // addSnackbar('Пожалуйста, выйдите из системы', 'info');
    }

    return () => {};
  }, [user, navigate]);

  return (
    <div className="mt-28 flex justify-center">
      {isComplete ? (
        <div className="p-4 md:p-12 md:text-4xl text-xl rounded-2xl bg-green-200 md:w-3/4 w-4/5 sm:w-1/2 min-h-[40%] overflow-hidden">
          {t("signup.reg_complete_msg_1", {
            name: watch("first_name"),
            surname: watch("last_name"),
          })}{" "}
          <span className="text-primary">{watch("email")}</span>{" "}
          {t("signup.reg_complete_msg_2")}
        </div>
      ) : (
        <div className="flex flex-col gap-2 rounded-md border bg-white p-8">
          <form
            className="flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              errorMsg={errors?.email?.message}
              inputClass={errors?.email?.message && "ring-error ring-1"}
              className="w-80"
              label={"Email"}
              placeholder={t("signup.enter_email")}
              type="text"
              required
              {...register("email", {
                required: t("signup.is_required_field_error", {
                  field: "email",
                }),
                maxLength: 64,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
            />
            <Input
              errorMsg={errors?.first_name?.message}
              inputClass={errors?.first_name?.message && "ring-error ring-1"}
              className="w-80"
              label={t("signup.name")}
              placeholder={t("signup.enter_name")}
              required
              {...register("first_name", {
                required: t("signup.is_required_field_error", {
                  field: t("signup.name")?.toLowerCase(),
                }),
                maxLength: 64,
              })}
            />
            <Input
              errorMsg={errors?.last_name?.message}
              inputClass={errors?.last_name?.message && "ring-error ring-1"}
              className="w-80"
              label={t("signup.surname")}
              placeholder={t("signup.enter_surname")}
              required
              {...register("last_name", {
                required: t("signup.is_required_field_error", {
                  field: t("signup.surname")?.toLowerCase(),
                }),
                maxLength: 64,
              })}
            />
            <Controller
              control={control}
              name="language"
              rules={{
                required: t("signup.is_required_field_error", {
                  field: t("signup.study_lang")?.toLowerCase(),
                }),
              }}
              render={({ field }) => (
                <Select
                  label={t("signup.study_lang")}
                  required
                  placeholder={t("signup.select_study_lang")}
                  isSearchable={false}
                  errorMsg={errors?.language?.message}
                  className="w-full"
                  value={field.value}
                  options={[
                    {
                      label: "Қазақша",
                      value: "K",
                    },
                    {
                      label: "Русский",
                      value: "R",
                    },
                  ]}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
            <div className="flex flex-row relative">
              <Input
                errorMsg={errors?.password?.message}
                inputClass={errors?.password?.message && "ring-error ring-1"}
                className="w-80"
                label={t("welcome.password")}
                placeholder={t("signup.enter_password")}
                type={isVisible ? "text" : "password"}
                required
                {...register("password", {
                  required: t("signup.is_required_field_error", {
                    field: t("welcome.password")?.toLowerCase(),
                  }),
                  maxLength: 64,
                  minLength: {
                    value: 8,
                    message: [1, 2, 3, 4, 5]
                      ?.map((e) => t(`signup.password_error.err_${e}`))
                      ?.join("\n"),
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                    message: [1, 2, 3, 4, 5]
                      ?.map((e) => t(`signup.password_error.err_${e}`))
                      ?.join("\n"),
                  },
                  validate: (value) => {
                    if (value === watch("email"))
                      return [1, 2, 3, 4, 5]
                        ?.map((e) => t(`signup.password_error.err_${e}`))
                        ?.join("\n");
                  },
                })}
              />
              <div className="absolute right-2 top-7 cursor-pointer text-primary">
                {isVisible ? (
                  <EyeIcon
                    className="h-5"
                    onClick={() => setIsVisible(false)}
                  />
                ) : (
                  <EyeSlashIcon
                    className="h-5"
                    onClick={() => setIsVisible(true)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row relative">
              <Input
                errorMsg={errors?.confirmPassword?.message}
                inputClass={
                  errors?.confirmPassword?.message && "ring-error ring-1"
                }
                className="w-80"
                label={t("welcome.confirm_password")}
                placeholder={t("signup.enter_password")}
                type={isVisibleConfirm ? "text" : "password"}
                required
                {...register("confirmPassword", {
                  required: t("signup.is_required_field_error", {
                    field: t("welcome.confirm_password")?.toLowerCase(),
                  }),
                  maxLength: 64,
                  validate: (value) => {
                    if (value !== watch("password"))
                      return t("signup.confirm_password_error");
                  },
                })}
              />{" "}
              <div className="absolute right-2 top-7 cursor-pointer text-primary">
                {isVisibleConfirm ? (
                  <EyeIcon
                    className="h-5"
                    onClick={() => setIsVisibleConfirm(false)}
                  />
                ) : (
                  <EyeSlashIcon
                    className="h-5"
                    onClick={() => setIsVisibleConfirm(true)}
                  />
                )}
              </div>
            </div>

            <Button
              loading={loading}
              type="submit"
              color="primary"
              text={t("welcome.registration")}
              className="mt-2"
            />
          </form>

          <div className="border-t" />
          <a href="/login" className="text-center text-primary hover:underline">
            {t("signup.already_have_account")}
          </a>
        </div>
      )}
    </div>
  );
}
