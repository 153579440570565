import bilAPI from "../api";

export const getSubscriptions = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/subscriptions/`, {
          params: { page_size: 200 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const getSubscriptionById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/payment/subscriptions/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};

export const deleteSubscription = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/payment/subscriptions/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("axios error");
        });
    } catch (error) {
      reject("system error");
    }
  });
};
