import { classNames } from "../../../utils/helpers/helpers";

export function PageButton({ children, className, ...rest }) {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-slate-200",
        className
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
