import React from "react";
import landing_boy from "../images/landing_boy.png";

export default function Frame() {
  return (
    <img src={landing_boy} alt="landing" className="clip-your-needful-style" />
    // <div className="relative w-64 h-48 bg-white border border-gray-300 overflow-hidden">
    //   <div className="absolute top-0 left-0 w-full h-16 bg-white rounded-b-full border-b border-gray-300" />
    //   <div className="relative z-10 p-4">
    //   </div>
    // </div>
  );
}
