import bilAPI from "../api";

export const postAttempt = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post("/tests/user_attempts/", body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchAttempt = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/tests/user_attempts/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
