import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import bilAPI from "../../services/api";
import { BASE_URL } from "../constants/constants.js";
import { UserContext } from "../contexts/UserContext";
import TokenService from "../../services/token.service.js";

export default function useAuth() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  const setUserContext = async (token) => {
    return await axios
      .get(BASE_URL + "/auth/my_account/", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setUser(response.data);
        if (response.data.roles.some((role) => role?.code === "ADMIN")) {
          navigate({
            pathname: `/admin/exams`,
            state: { user: user },
          });
        }
        if (response.data.roles.some((role) => role.code === "STD")) {
          navigate({
            pathname: `/student/profile`,
            state: { user: user },
          });
        }
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  const loginUser = async (data) => {
    const { username, password } = data;

    return axios
      .post(BASE_URL + "/auth/login/", {
        username,
        password,
      })
      .then(async (response) => {
        TokenService.updateLocalAccessToken(response.data.access);
        bilAPI.defaults.headers["Authorization"] =
          "Bearer " + response.data.access;
        TokenService.updateLocalRefreshToken(response.data.refresh);

        await setUserContext(response.data.access);
      })
      .catch((err) => {
        setError(err.response);
      });
  };

  return {
    loginUser,
    setUserContext,
    error,
  };
}
