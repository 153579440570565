import React from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../../../components/forms/Buttons/Button";
// import Select from "../../../../../components/forms/Select/Select";
import CustomDialog from "../../../../../components/modals/CustomDialog/CustomDialog";
import { patchExam } from "../../../../../services/tests/examsService";
// import { EXAMTYPES } from "../../../../../utils/constants/constants";
import Input from "../../../../../components/forms/Input/Input";
import useSnackbar from "../../../../../utils/hooks/useSnackbar";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import ToggleButton from "../../../../../components/forms/Buttons/ToggleButton";

const Edit = ({ exam, getData }) => {
  const { addSnackbar } = useSnackbar();

  const { register, handleSubmit, reset, control } = useForm();
  const [open, setOpen] = useState(false);

  const updateExamHandler = (data) => {
    data.uuid = exam?.uuid;
    if (!Number.isInteger(data?.duration / 1)) {
      addSnackbar("Длительность экзамена должно быть числом", "error");
      return;
    }
    setOpen(false);

    patchExam(exam?.uuid, data)?.finally(() => {
      getData();
      addSnackbar("Экзамен был успешно обновлен", "success");
    });
  };

  return (
    <>
      <Button
        Icon={PencilSquareIcon}
        className="rounded-r-none"
        onClick={() => {
          setOpen(true);
        }}
      />

      <CustomDialog
        isOpen={open}
        headerText="Подтвердите ваши действие"
        closeModal={() => {
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(updateExamHandler)}
          className="flex flex-col gap-2"
        >
          <div className="flex flex-col">
            <Input
              defaultValue={exam?.name}
              label="Название"
              inputClass="border"
              {...register("name", { required: true })}
              required
            />
            {/* <div className="flex flex-col ">
              <label className="block text-sm font-medium text-gray-700">
                Тип
                <span className={`text-error`}>{"*"}</span>
              </label>
              <Select
                value={watch("type") ? watch("type") : exam?.type}
                onChange={(v) => {
                  setValue("type", v?.value);
                }}
                options={EXAMTYPES?.map((type, i) => ({
                  label: type,
                  value: i,
                }))}
              />
            </div> */}
            <Input
              defaultValue={exam?.duration}
              label="Длительность экзамена"
              inputClass="border"
              {...register("duration", { required: true })}
              required
            />
            <Input
              defaultValue={exam?.variant}
              label="Вариант"
              inputClass="border"
              {...register("variant", { required: true })}
              required
            />
            <div className="mt-2 flex flex-row items-center gap-2">
              <label>Is free</label>

              <Controller
                control={control}
                name="is_free"
                defaultValue={exam?.is_free}
                render={({ field }) => (
                  <ToggleButton
                    value={field.value}
                    onClick={() => field.onChange(!field.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              text="Отмена"
              color="secondary"
              type="button"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            />
            <Button
              text="Сохранить"
              color="success"
              value="Submit"
              type="submit"
            />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Edit;
