import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/forms/Buttons/Button";
import Input from "../../../components/forms/Input/Input";
import { postPasswordResetForm } from "../../../services/auth/passwordService";

export default function PasswordReset() {
  const [t] = useTranslation();
  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    await postPasswordResetForm(data)
      .then(() => {
        setIsComplete(true);
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mt-28 flex justify-center">
      {isComplete ? (
        <div className="p-4 md:p-12 md:text-4xl text-xl rounded-2xl bg-green-200 md:w-3/4 w-4/5 sm:w-1/2 min-h-[40%] overflow-hidden">
          {t("signup.reset_password_message_1")}{" "}
          <span className="text-primary">{watch("email")}</span>{" "}
          {t("signup.reset_password_message_2")}
        </div>
      ) : (
        <div className="flex flex-col gap-2 rounded-md border bg-white p-8">
          <form
            className="flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              errorMsg={errors?.email?.message}
              inputClass={errors?.email?.message && "ring-error ring-1"}
              className="w-80"
              label={"Email"}
              placeholder={t("signup.enter_email")}
              type="text"
              required
              {...register("email", {
                required: t("signup.is_required_field_error", {
                  field: "email",
                }),
                maxLength: 64,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
            />

            <Button
              loading={loading}
              type="submit"
              color="primary"
              text={t("signup.reset_password")}
              className="mt-2"
            />
          </form>

          <div className="border-t" />
          <a href="/login" className="text-center text-primary hover:underline">
            {t("welcome.login")}
          </a>
        </div>
      )}
    </div>
  );
}
