import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/forms/Buttons/Button";
import { postOrder } from "../../../services/payment/orderService";
import { UserContext } from "../../../utils/contexts/UserContext";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ plan }) => {
  const [t] = useTranslation();
  const [isBuyLoading, setIsBuyLoading] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleBuy = () => {
    setIsBuyLoading(true);
    const body = {
      plan: plan.uuid,
      user: user.uuid,
    };
    postOrder(body)
      .then((res) => {
        navigate(`/student/order/${res.uuid}`);
      })
      .finally(() => {
        setIsBuyLoading(false);
      });
  };

  return (
    <div className="flex w-full flex-col ">
      <div className="grid grid-cols-1 place-items-center justify-start gap-5 sm:grid-cols-2 sm:place-items-stretch lg:grid-cols-3 xl:grid-cols-4 relative">
        <div
          className={`flex  w-[18rem] flex-col rounded-lg border `}
          key={plan?.uuid}
        >
          <p className="bg-slate-200 py-3 px-5">
            {/* {order + 1}. {exam?.name} */}
            {plan?.name}
          </p>
          <div className="flex h-full flex-col items-start justify-start p-5">
            <p className="mb-5">
              {t("payment.price")}: {plan?.sum} {t("payment.tenge")}
            </p>
          </div>
          <center className="mb-4">
            <Button
              text={t("payment.buy")}
              disabled={isBuyLoading}
              loading={isBuyLoading}
              onClick={handleBuy}
            ></Button>
          </center>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
