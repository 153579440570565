import React from "react";
import PasswordForm from "./PasswordForm";
import MainInfoForm from "./MainInfoForm";

const StudentSettings = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-2 rounded-md border bg-white p-8 w-fit">
        <MainInfoForm />
        <div className="my-4" />
        <PasswordForm />
      </div>
    </div>
  );
};

export default StudentSettings;
