import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/forms/Buttons/Button";
import useAuth from "../../../utils/hooks/useAuth";
import { UserContext } from "../../../utils/contexts/UserContext";
import { EyeIcon } from "@heroicons/react/24/outline";
import { EyeSlashIcon } from "@heroicons/react/24/outline";

export default function Login() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const {
    // formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const { user } = useContext(UserContext);
  const { loginUser } = useAuth();
  // const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    await loginUser(data).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user?.uuid) {
      if (user?.roles?.some((role) => role?.code === "ADMIN")) {
        navigate({
          pathname: `/admin/exams`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "VP")) {
        navigate({
          pathname: `/applicants`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "STD")) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
      // addSnackbar('Пожалуйста, выйдите из системы', 'info');
    }

    return () => {};
  }, [user, navigate]);

  return (
    <div className="mt-28 flex justify-center">
      <div className="flex flex-col gap-2 rounded-md border bg-white p-8">
        {/* <div className='flex flex-row justify-between'> */}
        {/* <div>
          <p className='px-14 py-2 text-xl font-bold'>Bilemtihan</p>
        </div> */}
        {/* <div className='border rounded-lg px-8 py-3'>
                  <img src={logo} alt='logo' width='32' height='32' />
                </div> */}
        {/* </div> */}
        <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col group relative mt-4">
            <input
              id="email"
              required
              className="block w-full rounded-md border-gray-300 px-3 py-2 pr-12 shadow-sm disabled:bg-slate-200 text-sm peer"
              {...register("username", {
                required: true,
                maxLength: 64,
              })}
            />

            <label
              htmlFor="password"
              className="text-sm top-2 mx-3 absolute transition-all delay-100 cursor-text
              group-focus-within:-top-5 group-focus-within:text-xs group-focus-within:mx-0
              peer-valid:-top-5 peer-valid:text-xs peer-valid:mx-0"
            >
              {"Email"}
              {/* <span className="text-error text-end"> *</span> */}
            </label>
          </div>

          <div className="flex flex-col group relative mt-4">
            <input
              id="password"
              type={isVisible ? "text" : "password"}
              required
              className="block w-full rounded-md border-gray-300 px-3 py-2 pr-12 shadow-sm disabled:bg-slate-200 text-sm peer"
              {...register("password", {
                required: true,
                maxLength: 64,
              })}
            />

            <div className="absolute right-2 bottom-2.5 cursor-pointer text-primary">
              {isVisible ? (
                <EyeIcon className="h-5" onClick={() => setIsVisible(false)} />
              ) : (
                <EyeSlashIcon
                  className="h-5"
                  onClick={() => setIsVisible(true)}
                />
              )}
            </div>
            <label
              htmlFor="password"
              className="text-sm top-2 mx-3 absolute transition-all delay-100 cursor-text
              group-focus-within:-top-5 group-focus-within:text-xs group-focus-within:mx-0
              peer-valid:-top-5 peer-valid:text-xs peer-valid:mx-0"
            >
              {t("welcome.password")}
              {/* <span className="text-error text-end"> *</span> */}
            </label>
          </div>

          <Button
            loading={loading}
            type="submit"
            color="primary"
            text={t("welcome.login")}
            className="mt-2"
          />
        </form>

        <div className="border-t"></div>

        <a
          href="/password-reset"
          className="text-center text-primary hover:underline"
        >
          {t("signup.forgot_password")}
        </a>
        <a
          href="/registration"
          className="text-center text-primary hover:underline"
        >
          {t("signup.create_account")}
        </a>
      </div>
    </div>
  );
}
