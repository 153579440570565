import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../utils/contexts/UserContext";
import Input from "../../../components/forms/Input/Input";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../components/forms/Select/Select";
import { Button } from "../../../components/forms/Buttons/Button";
import { patchMyAccount } from "../../../services/auth/accountService";
import useSnackbar from "../../../utils/hooks/useSnackbar";

export default function MainInfoForm() {
  const [t] = useTranslation();

  const { user } = useContext(UserContext);

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
  } = useForm();

  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    patchMyAccount(user.uuid, data)
      .then(() => {
        addSnackbar(
          t("signup.updated_successfully", { field: t("student.main_info") })
        );
      })
      .catch(() => {})
      ?.finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          errorMsg={errors?.email?.message}
          inputClass={errors?.email?.message && "ring-error ring-1"}
          className="w-80"
          label={t("signup.name")}
          placeholder={t("signup.name")}
          type="text"
          required
          {...register("first_name", {
            maxLength: 64,
            value: user.first_name,
          })}
        />
        <Input
          errorMsg={errors?.email?.message}
          inputClass={errors?.email?.message && "ring-error ring-1"}
          className="w-80"
          label={t("signup.surname")}
          placeholder={t("signup.surname")}
          type="text"
          required
          {...register("last_name", {
            maxLength: 64,
            value: user.last_name,
          })}
        />
        <Controller
          control={control}
          name="language"
          defaultValue={user.language}
          rules={{
            required: t("signup.is_required_field_error", {
              field: t("signup.study_lang")?.toLowerCase(),
            }),
          }}
          render={({ field }) => (
            <Select
              label={t("signup.study_lang")}
              required
              placeholder={t("signup.select_study_lang")}
              isSearchable={false}
              errorMsg={errors?.language?.message}
              className="w-80"
              value={field.value}
              options={[
                {
                  label: "Қазақша",
                  value: "K",
                },
                {
                  label: "Русский",
                  value: "R",
                },
              ]}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
        <center>
          <Button
            loading={loading}
            type="submit"
            color="primary"
            text={t("buttons.save")}
            className="mt-2"
          />
        </center>
      </form>
    </div>
  );
}
